import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpService } from '../../shared/services/http.service';


@Injectable({
    providedIn : "root"
})
export class ServerlessApiService {

    private readonly _API_BASE_URL = `https://${environment.serverless.host}/`;
   // private readonly _API_BASE_URL = `/sls-api/`; // for tests purpose

    constructor(private http: HttpService,) {

    }

    post = (url: string, params?: any, headers?: HttpHeaders, resType?: 'json'): Promise<any> => {
        return this._request("POST", url, params, headers);
    }

    get = (url: string, params?: any, headers?: HttpHeaders): Promise<any> => {
        return this._request("GET", url, params, headers);
    }

    private _request = (method: string, url: string, params?: any, headers?: HttpHeaders): Promise<any> => {
        return this.http.request(method, this._API_BASE_URL + url, params, headers)
            .then(data => {
                return Promise.resolve(data);
            }, response =>
                Promise.reject({responseJSON: response.data, status: response.status})
            )
    }

}
